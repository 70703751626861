<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {minLength, numeric, required} from "vuelidate/lib/validators";
import Master from "@/apis/Master";
import Swal from "sweetalert2";
// import VueSimpleSuggest from 'vue-simple-suggest'
// import 'vue-simple-suggest/dist/styles.css'
import Dropdown from 'vue-simple-search-dropdown'
import Vue from "vue";
import Multiselect from "vue-multiselect";

/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    Dropdown,
    Multiselect,
    // VueSimpleSuggest,
    PageHeader
  },
  data() {
    return {
      title: "SHOP BY ROOMS",
      items: [
        {
          text: "DASHBOARD",
          href:"/"
        },
        {
          text: "SHOP BY ROOMS",
        }
      ],
      checkbox:[],
      isCheckAll:false,
      shopByRoomData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      searchOption:[],
      searchOptionEdit:[],
      listSubCategoryData:[],
      searchData:"",
      searchDataEdit:"",
      sortBy: "id",
      sortDesc: true,
      selected:true,
      fields: [
        { key: "checkbox", sortable: false, label: ""},
        { key: "image", sortable: false, label: "IMAGE",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "listSubCategoryName", sortable: true, label: "CATEGORY",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "title", sortable: true, label: "TITLE",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "priority", sortable: true, label: "POSITION",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "status", sortable: true, label: "STATUS",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "action", label: "ACTION", sortable: false,  thStyle: { color: "black", "font-size":"16px"}}
      ],
      shopByRooms: {
        title: "",
        category_id: "",
        image: "",
        priority: "",
        status: 1
      },
      editShopByRooms:{
        title: "",
        category_id: "",
        image: "",
        priority: "",
        status: 1
      },
      submitted: false,
      editSubmitted: false,
      showmodal: false,
      editData: [],
      successMessage: "",
      errorMessage: "",
      imagePath:"",
      preview:"",
      editshowmodal: false
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.shopByRoomData.length;
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.shopByRoomData.length;

    Master.listSubCategoryList({
      trash: 0,
      subCategoryId: 0,
      status:1
    }).then((res) => {
      console.log(res)
      this.listSubCategoryData = res.data.data;

    });

    Master.shopByRoomList({
      trash: 0
    }).then((res) => {
      console.log(res)
      this.shopByRoomData = res.data.data;
    })
  },
  validations: {
    shopByRooms: {
      title: { required, minLength: minLength(3) },

      image:{ required },
      priority: { required, numeric },
      status: { required }
    },
    searchData:{ required },
    searchDataEdit:{ required },
    editShopByRooms:{
      title: { required, minLength: minLength(3) },
      priority: { required, numeric },
      status: { required }
    }
  },
  methods: {
    /**
     * Search the table data with search input
     */

    validateSelection(selection) {
      this.searchData = selection
      this.selected = selection;
      this.shopByRooms.category_id = selection.categoryId+","+selection.subCategoryIKd+","+selection.id
      // console.log(selection.name + " has been selected");
    },
    validateSelectionEdit(selection) {
      this.searchDataEdit = selection
      this.selected = selection;
      this.editShopByRooms.category_id = selection.categoryId+","+selection.subCategoryIKd+","+selection.id
      // console.log(selection.name + " has been selected");
    },

    getDropdownValues(keyword) {
       Master.searchProductType({
        searchStr:keyword
      }).then((res) => {
        console.log(res.data.data)
        this.searchOption = res.data.data
      })
      // console.log("You could refresh options by querying the API with " + keyword);
    },

    getDropdownValuesEdit(keyword) {
       Master.searchProductType({
        searchStr:keyword
      }).then((res) => {
        console.log(res.data.data)
        this.searchOptionEdit = res.data.data
      })
      // console.log("You could refresh options by querying the API with " + keyword);
    },

    // searchType(){
    //   return Master.searchProductType({
    //     searchStr:this.searchData
    //   }).then((res) => {
    //     console.log(res.data.data)
    //     this.searchOption = res.data.data
    //   })
    // },
    // onSuggestClick(event){
    //   this.shopByRooms.category_id = event.categoryId+","+event.subCategoryIKd+","+event.id
    // },
    checkAll: function(){
      console.log(this.perPage)
      this.isCheckAll = !this.isCheckAll;
      this.checkbox = [];
      if(this.isCheckAll){ // Check all
        for (var key in this.shopByRoomData) {
          console.log(key,this.shopByRoomData[key].id)
          this.checkbox.push(this.shopByRoomData[key].id);
        }
      }
    },

    async bulkActive(){
      if (this.checkbox[0] == null){
        alert("Please select atleast one checkbox")
      }else {
        Master.shopByRoomBulkActive({
          ids:this.checkbox,
          status: 1
        }).then((res) => {
          // this.successMessage = res.data.message;
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          this.checkbox = []
          this.isCheckAll = false
          Master.shopByRoomList({
            trash: 0
          }).then((res) => {
            console.log(res)
            this.shopByRoomData = res.data.data;
          })
        })
      }

    },
    async bulkInactive(){
      if (this.checkbox[0] == null){
        alert("Please select atleast one checkbox")
      }else {
        Master.shopByRoomBulkInActive({
          ids: this.checkbox,
          status: 0
        }).then((res) => {
          // this.successMessage = res.data.message;
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          this.checkbox = []
          this.isCheckAll = false
          Master.shopByRoomList({
            trash: 0
          }).then((res) => {
            console.log(res)
            this.shopByRoomData = res.data.data;
          })
        })
      }
    },

    onSuggestClick(event){
      this.editShopByRooms.category_id = event.categoryId+","+event.subCategoryIKd+","+event.id
    },

    async bulkDelete(){
      if (this.checkbox[0] == null){
        alert("Please select atleast one checkbox")
      }else {
        if (confirm('Are you sure you want to delete this item?')) {
          Master.shopByRoomBulkDelete({
            ids: this.checkbox,
            trash: 0
          }).then((res) => {
            // this.successMessage = res.data.message;
            Vue.swal({
              position: "center",
              icon: "success",
              title: "<p style='color: red'>"+res.data.message+"</p>",
              showConfirmButton: false,
              timer: 1500
            });
            this.checkbox = []
            this.isCheckAll = false
            Master.shopByRoomList({
              trash: 0
            }).then((res) => {
              console.log(res)
              this.shopByRoomData = res.data.data;
            })
          })
        }
      }
    },
    editModal(data){
      this.editshowmodal = true
      Master.shopByRoomDetails({
        id:data
      }).then((res) => {
        this.editShopByRooms.id = res.data.data.id
        this.editShopByRooms.title = res.data.data.title
        this.searchOptionEdit = {id: res.data.data.list_sub_category_id, name: res.data.data.listSubCategoryName}
        this.editShopByRooms.priority = res.data.data.priority
        // Master.searchProductType({
        //   searchStr:res.data.data.listSubCategoryName
        // }).then((res) => {
        //   // console.log(res.data.data)
        //   this.searchOptionEdit = res.data.data
        //   this.searchDataEdit = res.data.data
        //   this.selected = res.data.data
        // })
        this.editShopByRooms.category_id = res.data.data.category_id+","+res.data.data.sub_category_id+","+res.data.data.list_sub_category_id


        this.preview = res.data.data.image
        this.editShopByRooms.status = res.data.data.status
        console.log(res)
      })
    },

    sliderImageUpload(event){
      this.shopByRooms.image = event.target.files[0]
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        }
        this.editShopByRooms.image=input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
      console.log(event)
    },

    async deleteShopByRoomDelete(data){
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          Master.shopByRoomDelete(
              {
                trash: 0,
                id: data
              }).then(res => {
            console.log(res)
            Swal.fire("Deleted!", "Your data has been deleted.", "success");
            // this.successMessage = res.data.message;
            Master.shopByRoomList({
              trash: 0
            }).then((res) => {
              console.log(res)
              this.shopByRoomData = res.data.data;
            })
          })
        }
      });
    },

    async handleSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.shopByRooms.$invalid) {
        return;
      } else {
        const fd = new FormData();
        const config = { headers: { "Content-Type": "multipart/form-data" } };
        fd.append("image",this.shopByRooms.image);
        fd.append('data',JSON.stringify({
              "title" : this.shopByRooms.title,
              "category" : this.shopByRooms.category_id,
              "priority" : this.shopByRooms.priority,
              "status" : this.shopByRooms.status
            }
        ));
        Master.shopByRoomAdd(fd,config).then((res) => {
          console.log(res)
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          // this.successMessage = res.data.message;

          Master.shopByRoomList({
            trash: 0
          }).then((res) => {
            console.log(res)
            this.shopByRoomData = res.data.data;
          })
          this.showmodal = false;
        }).catch((err) => {
          console.log(err)
          // this.errorMessage = err.response.data.error.name[0];
          this.$bvToast.toast(""+err.response.data.error.title[0]+"", {
            title: `Error Message`,
            variant: 'danger',
            solid: true
          });
          this.showmodal = false;
        })

      }
      this.submitted = false;
      this.shopByRooms.status = 1;
      this.shopByRooms.title = "";
      this.searchData = "";
      this.selected = "";
      this.searchOption = [];
      this.shopByRooms.category_id = "";
      this.shopByRooms.priority = "";
      this.preview = "";
      this.imagePath = "";
    },

    handleUpdate(){
      this.editSubmitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.editShopByRooms.$invalid) {
        return;
      } else {
        const fd = new FormData();
        const config = { headers: { "Content-Type": "multipart/form-data" } };
        fd.append("image",this.editShopByRooms.image);
        fd.append('data',JSON.stringify({
              "id" : this.editShopByRooms.id,
              "title" : this.editShopByRooms.title,
              "category" : this.editShopByRooms.category_id,
              "priority" : this.editShopByRooms.priority,
              "status" : this.editShopByRooms.status
            }
        ));
        Master.shopByRoomUpdate(fd,config).then((res) => {
          console.log(res)
          // this.successMessage = res.data.message;
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          Master.shopByRoomList({
            trash: 0
          }).then((res) => {
            console.log(res)
            this.shopByRoomData = res.data.data;
          })
          this.editshowmodal = false;
        }).catch((err) => {
          console.log(err)
          // this.errorMessage = err.response.data.errors;
          this.$bvToast.toast(""+err.response.data.errors+"", {
            title: `Error Message`,
            variant: 'danger',
            solid: true
          });
          this.editshowmodal = false;
        })
      }
      this.editSubmitted = false;
    },
    hideModal() {
      this.shopByRooms.title = "";
      this.searchData = "";
      this.selected = "";
      this.searchOption = [];
      this.shopByRooms.category_id = "";
      this.shopByRooms.priority = "";
      this.preview = "";
      this.imagePath = "";
      this.submitted = false;
      this.showmodal = false;
    },
    hideEditModal() {
      this.editSubmitted = false;
      this.editshowmodal = false;
      this.editShopByRooms.title = "";
      this.searchData = "";
      this.selected = "";
      this.searchOption = [];
      this.editShopByRooms.category_id = "";
      this.editShopByRooms.priority = "";
      this.preview = "";
      this.imagePath = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<template>
  <Layout>

    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" style="text-transform:uppercase !important;">
            <div class="btn-toolbar">
              <a href="javascript:void(0);" class="btn btn-primary mb-2 mr-1"  @click="showmodal = true">
                <i class="mdi mdi-plus mr-2"></i> Add
              </a>
              <a href="javascript:void(0);" class="btn btn-outline-success mb-2 mr-1" @click="bulkActive">
                <i class="mdi mdi-check mr-2"></i> Active
              </a>
              <a href="javascript:void(0);" class="btn btn-outline-danger mb-2 mr-1" @click="bulkInactive">
                <i class="mdi mdi-cancel mr-2"></i> In-Active
              </a>
              <a href="javascript:void(0);" class="btn btn-danger mb-2 mr-1" @click="bulkDelete">
                <i class="mdi mdi-trash-can mr-2"></i> Delete
              </a>
            </div>
            <b-alert show dismissible variant="success" v-if="successMessage">
              <i class="mdi mdi-check-all mr-2"></i>{{successMessage}}
            </b-alert>
            <b-alert show dismissible variant="danger" v-if="errorMessage">
              <i class="mdi mdi-block-helper mr-2"></i>{{ errorMessage }}
            </b-alert>
            <b-tabs nav-class="nav-tabs-custom">
              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show&nbsp;
                      <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <div class="table-responsive">
                <b-table
                    class="table-centered"
                    :items="shopByRoomData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    show-empty
                    striped
                    sort-icon-left
                >
                  <template v-slot:head(checkbox)>
                    <div class="form-check mb-1">
                      <input
                          type="checkbox"
                          class="form-check-input"
                          v-model="isCheckAll"
                          :id="`customercheck`"
                          @click='checkAll()'
                      />
                      <label class="form-check-label" :for="`customercheck`"></label>
                    </div>
                  </template>

                  <template v-slot:cell(listSubCategoryName)="row">
                    <p class="badge font-size-12">
                      {{row.item.listSubCategoryName}}
                    </p>
                  </template>
                  <template v-slot:cell(priority)="row">
                    <p class="badge font-size-12">
                      {{row.item.priority}}
                    </p>
                  </template>
                  <template v-slot:cell(title)="row">
                    <p class="badge font-size-12">
                      {{row.item.title}}
                    </p>
                  </template>



                  <template  v-slot:cell(image) = "data">
                    <img
                        :src="data.item.image"
                        alt="shop-by-room-img"
                        title="shop-by-room-img"
                        class="avatar-md"
                    />
                  </template>
                  <template v-slot:cell(status)="row">
                    <div v-if="row.value == 1"
                         class="badge font-size-12 badge-soft-success"
                    >ACTIVE</div>
                    <div v-if="row.value == 0"
                         class="badge font-size-12 badge-soft-danger"
                    >IN-ACTIVE</div>
                  </template>
                  <template v-slot:cell(checkbox)="row">
                    <div class="form-check mb-3">
                      <input
                          type="checkbox"
                          class="form-check-input"
                          v-model="checkbox"
                          :id="`customercheck${row.index}`"
                          :value="row.item.id"
                      />
                      <label class="form-check-label" :for="`customercheck${row.index}`">&nbsp;</label>
                    </div>
                  </template>
                  <template v-slot:cell(action) = "data">
                    <a
                        href="javascript:void(0);"
                        class="mr-3 text-primary"
                        @click="editModal(data.item.id)"
                        v-b-tooltip.hover
                        title="Edit"
                    >
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </a>
                    <a
                        href="javascript:void(0);"
                        class="text-danger"
                        @click="deleteShopByRoomDelete(data.item.id)"
                        v-b-tooltip.hover
                        title="Delete"
                    >
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                          aria-controls
                      >
                      </b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
              <!--              </b-tab>-->
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Add -->
    <b-modal
        id="modal-1"
        v-model="showmodal"
        title="ADD SHOP BY ROOM"
        title-class="text-dark font-18"
        hide-footer
        @close="hideModal"
    >

      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="name">TITLE *</label>
              <input
                  id="name"
                  v-model="shopByRooms.title"
                  type="text"
                  class="form-control"
                  placeholder="ENTER TITLE"
                  :class="{ 'is-invalid': submitted && $v.shopByRooms.title.$error }"
                  style="text-transform: uppercase !important;"
              />
              <div v-if="submitted && $v.shopByRooms.title.$error" class="invalid-feedback">
                <span v-if="!$v.shopByRooms.title.required">SLIDER TITLE IS REQUIRED</span>
                <span v-if="!$v.shopByRooms.title.minLength">SLIDER TITLE SHOULD BE ATLEAST 3 CHARACTER</span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="code">POSITION *</label>
              <input
                  id="code"
                  v-model="shopByRooms.priority"
                  type="text"
                  class="form-control"
                  placeholder="ENTER POSITION"
                  :class="{ 'is-invalid': submitted && $v.shopByRooms.priority.$error }"
              />
              <div v-if="submitted && $v.shopByRooms.priority.$error" class="invalid-feedback">
                <span v-if="!$v.shopByRooms.priority.required">POSITION IS REQUIRED</span>
                <span v-if="!$v.shopByRooms.priority.numeric">POSITION MUST BE NUMERIC</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>PRODUCT TYPE *</label>
<!--              <vue-simple-suggest-->
<!--                  v-model="searchData"-->
<!--                  :list="this.searchOption"-->
<!--                  :filter-by-query="true"-->
<!--                  @input="searchType"-->
<!--                  display-attribute="name"-->
<!--                  value-attribute="categoryId"-->
<!--                  @suggestion-click="onSuggestClick"-->
<!--                  placeholder="PRODUCT TYPE SEARCH..."-->
<!--                  :class="{ 'is-invalid': submitted && $v.shopByRooms.category_id.$error }"-->
<!--              >-->
<!--              </vue-simple-suggest>-->

              <Dropdown
                  :options="searchOption"
                  v-on:selected="validateSelection"
                  v-on:filter="getDropdownValues"
                  :disabled="false"
                  :class="{ 'is-invalid': submitted && $v.searchData.$error }"
                  placeholder="PRODUCT TYPE SEARCH">
              </Dropdown>
              <div
                  v-if="submitted && !$v.searchData.required"
                  class="invalid-feedback"
              >PRODUCT TYPE IS REQUIRED</div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="position">STATUS</label>
              <select class="form-control"
                      id="position"
                      v-model="shopByRooms.status"
                      :class="{ 'is-invalid': submitted && $v.shopByRooms.status.$error }">
                <option value="1">ACTIVE</option>
                <option value="0">IN-ACTIVE</option>
              </select>
              <div
                  v-if="submitted && !$v.shopByRooms.status.required"
                  class="invalid-feedback"
              >Status is required</div>
            </div>
          </div>
        </div>


        <div class="form-group">
          <label for="image">IMAGE * <span style="font-size: 12px">(512 x 512)</span></label>
          <br/>
          <input
              id="image"
              type="file"
              @change="sliderImageUpload"
              :class="{ 'is-invalid': submitted && $v.shopByRooms.image.$error }"
          />
          <div v-if="submitted && $v.shopByRooms.image.$error" class="invalid-feedback">
            <span v-if="!$v.shopByRooms.image.required">IMAGE IS REQUIRED</span>
          </div>
          <!--          Image Preview-->
          <div class="border p-2 mt-3">
            <template v-if="preview">
              <img :src="preview" class="avatar-md"/>
            </template>
          </div>

        </div>



        <div class="text-right">
          <button type="submit" class="btn btn-success">SAVE</button>
          <b-button class="ml-1" variant="danger" @click="hideModal">CANCEL</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->


    <!-- Modal Edit -->
    <b-modal
        id="modal-1"
        v-model="editshowmodal"
        title="EDIT SHOP BY ROOM"
        title-class="text-dark font-18"
        hide-footer
        @close="hideEditModal"
    >
      <form @submit.prevent="handleUpdate">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="title">TITLE *</label>
              <input
                  id="title"
                  v-model="editShopByRooms.title"
                  type="text"
                  class="form-control"
                  placeholder="ENTER TITLE"
                  :class="{ 'is-invalid': editSubmitted && $v.editShopByRooms.title.$error }"
                  style="text-transform: uppercase !important;"
              />
              <div v-if="editSubmitted && $v.editShopByRooms.title.$error" class="invalid-feedback">
                <span v-if="!$v.editShopByRooms.title.required">TITLE IS REQUIRED</span>
                <span v-if="!$v.editShopByRooms.title.minLength">TITLE SHOULD BE ATLEAST 3 CHARACTER</span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="Editposition">POSITION *</label>
              <input
                  id="Editposition"
                  v-model="editShopByRooms.priority"
                  type="text"
                  class="form-control"
                  placeholder="ENTER POSITION"
                  :class="{ 'is-invalid': editSubmitted && $v.editShopByRooms.priority.$error }"
              />
              <div v-if="editSubmitted && $v.editShopByRooms.priority.$error" class="invalid-feedback">
                <span v-if="!$v.editShopByRooms.priority.required">POSITION IS REQUIRED</span>
                <span v-if="!$v.editShopByRooms.priority.numeric">POSITION MUST BE NUMERIC</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>PRODUCT TYPE *</label>
<!--              <vue-simple-suggest-->
<!--                  v-model="searchData"-->
<!--                  :list="this.searchOption"-->
<!--                  :filter-by-query="true"-->
<!--                  @input="searchType"-->
<!--                  display-attribute="name"-->
<!--                  value-attribute="categoryId"-->
<!--                  @suggestion-click="onSuggestClick"-->
<!--                  placeholder="PRODUCT TYPE SEARCH..."-->

<!--              >-->
<!--              </vue-simple-suggest>-->

<!--              <Dropdown-->
<!--                  :options="searchOptionEdit"-->
<!--                  v-on:selected="validateSelectionEdit"-->
<!--                  v-on:filter="getDropdownValuesEdit"-->
<!--                  :disabled="false"-->

<!--                  :class="{ 'is-invalid': editSubmitted && $v.searchDataEdit.$error }"-->
<!--                  placeholder="PRODUCT TYPE SEARCH">-->
<!--              </Dropdown>-->

              <multiselect v-model="searchOptionEdit"
                           :options="listSubCategoryData"
                           track-by="name"
                           label="name"
                           value="id"
                           placeholder="PRODUCT TYPE SEARCH"
                           :class="{ 'is-invalid': editSubmitted && $v.searchDataEdit.$error  }"
                           @input="onSuggestClick"
              >

              </multiselect>



              <!--            <div-->
              <!--                v-if="submitted && !$v.editShopByRooms.category_id.required"-->
              <!--                class="invalid-feedback"-->
              <!--            >PRODUCT TYPE IS REQUIRED</div>-->
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="editStatus">STATUS</label>
              <select class="form-control"
                      id="editStatus"
                      v-model="editShopByRooms.status"
                      :class="{ 'is-invalid': editSubmitted && $v.editShopByRooms.status.$error }">
                <option value="1">ACTIVE</option>
                <option value="0">IN-ACTIVE</option>
              </select>
              <div
                  v-if="editSubmitted && !$v.editShopByRooms.status.required"
                  class="invalid-feedback"
              >Status is required</div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="editImage">IMAGE * <span style="font-size: 12px">(512 x 512)</span></label>
          <br/>
          <input
              id="editImage"
              type="file"
              @change="sliderImageUpload"
          />
          <!--          Image Preview-->
          <div class="border p-2 mt-3">
            <template v-if="preview">
              <img :src="preview" class="avatar-md"/>
            </template>
          </div>

        </div>

        <div class="text-right">
          <button type="submit" class="btn btn-success">UPDATE</button>
          <b-button class="ml-1" variant="danger" @click="hideEditModal">CANCEL</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>